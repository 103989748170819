@import "../../../src/styles/variables.scss";
@import "../../../src/styles/fonts.scss";

.header-wrap-container {
  box-sizing: border-box;
  z-index: 10000;
  position: fixed;
  top: 0;
  left: 50%;
  width: 101%;
  transform: translateX(-50%);
  box-sizing: border-box;
  height: 5.5rem;
  background-color: none;
  backdrop-filter: blur(0px);
  transition: backdrop-filter 0.1s linear;
  transition: background-color .1s linear;
}

.blur {
  background-color: rgba(61, 72, 81, .6);
  backdrop-filter: blur(20px);
  transition: backdrop-filter 0.2s linear;
  transition: background-color .2s linear;
}

.header-wrapper {
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 0 8rem;
  margin: auto;

  @include mobile {
    width: 100%;
  }

  @include fullHd {
    max-width: 1750px;
  }

  @include quadHd {
    max-width: 2200px;
  }

  .logo-wrapper {
    border-radius: 0.5rem;
    padding:0 1rem;

    cursor: pointer;
    display: flex;
    align-items: center;

    .logo-img {
      height: 3.5rem;
    }
  }

  .bell-wrapper {
    width: 1.5rem;
    height: 1.5rem;
    margin: 0 2.5rem 0 1rem;
  }

  .propic-wrapper {
    width: 3rem;
    height: 3rem;
    border-radius: 2rem;
    background-color: grey;

    .propic {
      width: 100%;
      height: 100%;
      border-radius: 2rem;
    }
  }
}

.schedule-demo-button {
  position: relative;
  padding: .5rem 1rem 1rem !important;
  line-height: 1 !important;

  .schedule-text {
    &::after {
      content: "";
      position: absolute;
      bottom: .375rem;
      width: 1rem;
      height: 2px;
      left: 1rem;
      background-color: $white;
      transition: background-color .35s, width .35s;
    }
  }
}

.navbar {
  display: none;
  align-items: center;
  padding: .5rem 1rem 1rem;
  font-size: .875rem;
  @include open-sans-medium();

  @include desktop {
    display: flex;
  }

  .nav-icon {
    position: relative;
    min-width: max-content;
    color: $white;
    text-decoration: none;
    margin: 0 2.5rem;
    border-radius: 0.4rem;
    font-size: .875rem;

    .nav-icon-text {
      padding: 0.5rem 1rem 1rem;

      &:hover {
        background-color: $black;
        padding: 0.5rem 1rem 1rem;
        border-radius: 0.375rem;
        transition: .2s all;
      }

      &::after {
        content: "";
        position: absolute;
        bottom: -.5rem;
        width: 1rem;
        height: 2px;
        left: 1rem;
        background-color: $white;
        transition: background-color .35s, width .35s;
      }
    }
  }

  .nav-icon.active {
    .nav-icon-text {
      opacity: .5;
      background-color: $black;
      border-radius: 0.375rem;

      &:hover {
        opacity: 1;
        transition: .2s all;
      }
    }
  }
}
@import "../src/styles/variables.scss";
@import "../src/styles/fonts.scss";
@import "../src/styles/base.scss";
@import "../src/styles/utils.scss";


.page-container {
  box-sizing: border-box;
  background-color: $background-grey-1;
  min-height: 100vh;
  padding: 0 8rem;
}

.page-margin {
  padding: 0 8rem;
}

.button {
  text-transform: none !important;
  min-width: max-content !important;
}

.prime-button {
  @include open-sans-medium(true);
  font-size: 0.875rem !important;
  background-color: $primary-blue !important;
  color: $white !important;
  border: 2px solid $primary-blue !important;

  &:hover {
    background-color: $primary-blue-hover !important;
    border: 2px solid $primary-blue-hover !important;
  }
}

.second-button {
  color: $white !important;
  border: 2px solid $white !important;
  padding: 0.75rem 1.5rem !important;
  margin-top: 2rem !important;
  font-size: 1rem !important;
}

body {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
@import "./responsive.scss";

html {
  height: 100%;

  @include mobile {
    font-size: 14px;
  }

  @include tablet {
    font-size: 12px;
  }

  @include desktop {
    font-size: 13px;
  }

  @include hd {
    font-size: 14px;
  }

  @include hdPlus {
    font-size: 16px;
  }

  @include fullHd {
    font-size: 16px;
  }

  @include quadHd {
    font-size: 18px;
  }

  @include uHd {
    font-size: 22px;
  }
}
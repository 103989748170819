:root {
  background-color: rgb(61, 61, 61);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.d-flex {
  display: flex;
}

.box-b {
  box-sizing: border-box;
}

.align-c {
  align-items: center;
}

.justify-c {
  justify-content: center;
}

.justify-sb {
  justify-content: space-between;
}

.justify-se {
  justify-content: space-evenly;
}

.col-12 {
  width: 100%;
}

.col-10 {
  width: 83.33%;
}

.col-9 {
  width: 75%
}

.col-8 {
  width: 66.66%;
}

.col-6 {
  width: 50%;
}

.col-4 {
  width: 33.33%;
}

.col-3 {
  width: 25%;
}

.col-2 {
  width: 16.66%;
}

.col-1 {
  width: 8.33%;
}

h1,h2,h3,h4,h5,h6 {
  margin: 0;
}

p {
  margin-top: 0;
}

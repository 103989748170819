@import "../../../src/styles/variables.scss";
@import "../../../src/styles/fonts.scss";

.home {
  width: 100%;

  .video-overlay {
    position: relative;
    padding-top: 16rem;
    width: 100%;
    max-height: 100vh; // to maintain overlay height same in all screen
    display: flex;
    flex-flow: column;
    z-index: 0;
    background-color: $black;

    video {
      position: absolute;
      top: -.4rem;
      left: 0;
      z-index: -1;
      width: calc(100vw - 15px);
      // min-height: 100vh;
    }

    .inset-overlay {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 0;
      width: 100%;
      min-height: 120vh;
      box-shadow: inset 0 0 20rem 12rem rgba(0, 0, 0, 0.75);
    }

    .left-blur {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 0;
      width: 60%;
      height: 120vh;
      filter: blur(5px);
      backdrop-filter: blur(6px);
      mask: linear-gradient(to right, black 50%, transparent);
    }

    .section-1 {
      z-index: 1;

      h1 {
        @include open-sans-semibold();
        display: flex;
        flex-direction: column;
        font-size: 5rem;
        color: $white;
        line-height: 1;
      }

      .sec-one-desc {
        @include open-sans-medium();
        color: $white;
        font-size: 1rem;
        min-width: 25rem;
        width: 40%;
      }
    }
  }

  .blue-line {
    width: 3rem;
    height: .375rem;
    background-color: $primary-blue;
    margin: 0 0 2rem 0;
  }

  .blue-line-thin {
    width: 2.5rem;
    height: .25rem;
    background-color: $primary-blue;
    margin: 0 0 2rem 0;
  }

  .arrow-button {
    font-size: 1rem;
    margin-bottom: 2rem;

    img {
      height: 1.25rem;
      padding-left: .5rem;
    }
  }

  .infinity-img {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    border-radius: .5rem;
    width: 5rem;
    transform: translate(2rem, -50%);
  }

  .section-2 {
    box-sizing: border-box;
    background-color: $black;
    padding-top: 30rem;
    padding-bottom: 20rem;
    min-height: 100vh;

    .first-column {
      position: relative;
      margin-right: 3rem;
      padding: 4rem 2rem 3rem;
      background-color: rgba(72, 103, 125, .4);
      color: $white;
      border-radius: .5rem;
      height: max-content;

      h3 {
        flex-direction: column;
        font-size: 2rem;
        @include open-sans-semibold();
        padding-bottom: 2rem;
        line-height: 1.4;
      }

      p {
        line-height: 1.5;
        font-size: 1.125rem;
        color: $placeholder-grey;
      }
    }

    .second-column {
      flex-wrap: wrap;

      .sec-img-wrap {
        box-sizing: border-box;
        width: 50%;
        background-size: cover;
        background-position: center;

        .sec-img {
          border-radius: .5rem;
          width: 100%;
          height: 22rem;
          background-size: cover;
          background-position: center;
          margin-bottom: 3rem;
        }

        &:nth-of-type(odd) {
          .sec-img {
            margin-right: 3rem;
          }
        }

        &:nth-of-type(even) {
          .sec-img {
            margin-left: 3rem;
          }
        }
      }
    }

    .button-wrap {
      flex-direction: column;

      .arrow-button {
        width: 50%;
      }
    }
  }

  .section-3 {
    min-height: 100vh;
    position: relative;
    background-color: $background-grey-2;

    .latency-box {
      box-sizing: border-box;
      z-index: 5;
      position: absolute;
      top: 0;
      width: calc(50% - 8rem);
      height: 25rem;
      background-color: rgba(255, 255, 255, 0.6);
      transform: translateY(-50%);
      border-radius: .5rem;
      backdrop-filter: blur(10px);
      padding: 2rem;
    }

    h3 {
      @include open-sans-semibold();
      font-size: 2rem;
      color: $black;
      padding: 5rem 0 1rem;
    }

    p {
      color: $text-grey-5;
      font-size: 1.125rem;
    }
  }

  .section-4 {
    min-height: 100vh;
    background-color: $black;
    padding-bottom: 10rem;

    .dummy-1 {
      z-index: -1;
    }

    .product-box-wrap {
      position: sticky;
      top: 0;
      z-index: 0;

      .product-bg {
        position: absolute;
        top: 10rem;
        right: 0;

        img {
          position: relative;
          left: 8rem;
          width: 75vw;
          opacity: .5;
        }
      }

      .product-box {
        margin: 18rem 0;
        position: relative;
        z-index: 1;
        top: 0rem;
        margin-right: 3rem;
        padding: 6rem 2rem 3rem;
        background-color: rgba(72, 103, 125, .4);
        color: $white;
        border-radius: .5rem;
        height: max-content;
        backdrop-filter: blur(10px);

        p {
          @include open-sans-semibold();
          font-size: 2rem;
        }

        .arrow-button {
          padding: .75rem 6rem !important;
        }
      }
    }

    .scroll-wrap {
      position: relative;
      z-index: 2;
    }

    .scrolling-text-wrap {
      .scrolling-text {
        .blue-line-wrap {
          width: 10%;
          display: flex;
          justify-content: flex-end;

          .blue-line-thin {
            margin-top: 1rem;
          }
        }

        p {
          width: 85%;
          font-size: 2.25rem;
          padding-left: 2rem;
          color: $white;
          margin-bottom: 20rem;
        }
      }
    }
  }

  .section-5 {
    min-height: 100vh;
    width: 100%;
    background-color: $black;
    background-image: url('../../assets/desert.png');
    background-size: cover;
    background-repeat: no-repeat;

    .top-blur {
      width: 100%;
      height: 20rem;
      background-color: rgb(0, 0, 0);
      mask: linear-gradient(to bottom, black 0.01%, transparent);
    }

    .sec-5-content {
      h1 {
        @include open-sans-semibold();
        font-size: 9rem;
        color: rgba(255, 255, 255, 0.4);
        flex-direction: column;
        letter-spacing: -.4rem;
        padding-bottom: 10rem;
      }
    }
  }
}


// object-ft: 
// object-position:
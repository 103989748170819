// @import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:opsz,wght@6..12,200;6..12,300;6..12,400;6..12,600;6..12,700;6..12,800;6..12,900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Roboto+Sans:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');


@mixin open-sans-light() {
  font-family: 'Open Sans', sans-serif;
  font-weight: 300;
}

@mixin open-sans-regular() {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
}

@mixin open-sans-medium($important: false) {
  $imp: null;

  @if $important {
    $imp:  !important;
  }

  font-family: "Open Sans", sans-serif $imp;
  font-weight: 500 $imp;
}

@mixin open-sans-semibold($important: false) {
  $imp: null;

  @if $important {
    $imp:  !important;
  }

  font-family: 'Open Sans', sans-serif $imp; 
  font-weight: 600 $imp;
}

@mixin open-sans-bold($important: false) {
  $imp: null;

  @if $important {
    $imp:  !important;
  }

  font-family: 'Open Sans', sans-serif $imp;
  font-weight: 700 $imp;
}

@mixin open-sans-extrabold() {
  font-family: 'Open Sans', sans-serif;
  font-weight: 800;
}


// @mixin nunito-thin() {;
//   font-family: 'Nunito Sans', sans-serif;
//   font-weight: 200;
// }

// @mixin nunito-light() {
//   font-family: 'Nunito Sans', sans-serif;
//   font-weight: 300;
// }

// @mixin nunito-regular($important: false) {
//   $imp: null;

//   @if $important {
//     $imp:  !important;
//   }

//   font-family: 'Nunito Sans',
//   sans-serif $imp;
//   font-weight: 400 $imp;
// }

// @mixin nunito-semibold($important: false) {
//   $imp: null;

//   @if $important {
//     $imp:  !important;
//   }

//   font-family: 'Nunito Sans', sans-serif $imp; 
//   font-weight: 600 $imp;
// }

// @mixin nunito-bold($important: false) {
//   $imp: null;

//   @if $important {
//     $imp:  !important;
//   }

//   font-family: 'Nunito Sans',
//   sans-serif $imp;
//   font-weight: 700 $imp;
// }

// @mixin nunito-extrabold() {
//   font-family: 'Nunito Sans', sans-serif;
//   font-weight: 800;
// }

// @mixin nunito-black() {
//   font-family: 'Nunito Sans', sans-serif;
//   font-weight: 900;
// }






// @mixin roboto-thin() {
//   font-family: 'Nunito Sans', sans-serif;
//   font-weight: 100;
// }

// @mixin roboto-light() {
//   font-family: 'Nunito Sans', sans-serif;
//   font-weight: 300;
// }

// @mixin roboto-regular($important: false) {
//   $imp: null;

//   @if $important {
//     $imp:  !important;
//   }

//   font-family: 'Nunito Sans',
//   sans-serif $imp;
//   font-weight: 400 $imp;
// }

// @mixin roboto-medium($important: false) {
//   $imp: null;

//   @if $important {
//     $imp:  !important;
//   }

//   font-family: 'Nunito Sans', sans-serif $imp; 
//   font-weight: 500 $imp;
// }

// @mixin roboto-bold($important: false) {
//   $imp: null;

//   @if $important {
//     $imp:  !important;
//   }

//   font-family: 'Nunito Sans',
//   sans-serif $imp;
//   font-weight: 700 $imp;
// }

// @mixin roboto-black() {
//   font-family: 'Nunito Sans', sans-serif;
//   font-weight: 900;
// }


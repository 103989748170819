@import "./responsive.scss";
@import "./breakpoints.scss";

$primary-text: #232E3C;
$secondary-text: #4D5763;
$primary-blue: #297fff;;
$primary-blue-hover: #0A5385;

$black: #000;
$white: #fff;
$red : #DA4C4C;
$green: #508364;
$yellow: #B5771A;


$background-red: #FFEBEB;
$background-yellow: #FFF8E1;
$background-green: #E9F9E3;

$background-grey-1: #F8F8F8;
$background-grey-2: #BEBEBE;

$placeholder-grey: #CACDCD;

$text-grey-2: #8690A9;
$text-grey-3: #7A8696;
$text-grey-4: #7D8A8D;
$text-grey-5: #555;

$border-grey-1: #DEE0E5;
$border-grey-2: #EDEDED;
$border-grey-3: #EBF1F2;